import LazyLoad from "vanilla-lazyload";
import toggleMobileNavigation from "./partials/hamburger-menu.js";

let goToTopButton = document.getElementById("goToTop");

function goToTop(scrollDuration) {
    var scrollDuration = 600;

    let scrollStep = -window.scrollY / (scrollDuration / 15),
        scrollInterval = setInterval(function () {
            if (window.scrollY != 0) {
                window.scrollBy(0, scrollStep);
            } else clearInterval(scrollInterval);
        }, 15);
}

if (goToTopButton) {
    goToTopButton.addEventListener("click", goToTop, false);
}

// LazyLoad Images
const lazyLoadOptions = {
    use_native: true, // <-- there you go
};

var myLazyLoad = new LazyLoad(lazyLoadOptions);

// Hamburger menu
let hamburgerMenu = document.getElementById("hamburgerMenu");

if (hamburgerMenu) {
    hamburgerMenu.addEventListener("click", toggleMobileNavigation, false);
}
